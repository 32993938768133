import Cookies from "js-cookie";
import { CSSObject } from "styled-components";
import { Vars as PatientSearchParams } from "routes/patientSearch/queries/useListPatients";
import { Vars as TherapistSearchParams } from "routes/therapistSearch/queries/useListTherapists";
import { Vars as ClaimSearch } from "routes/rcm/queries/useRCM";
import { ReportVars as ReportSearch } from "routes/reports/queries/report";
import { Country } from "./market";

// Resolve URL fragments like: ("https://localhost:3000", "auth/signin", "admin") => "https://localhost:3000/auth/signin/admin"
export const join = (...frag: Array<number | string | URLSearchParams | undefined>) =>
  frag
    .map(f => String(f ?? ""))
    .filter(Boolean)
    .map(f => {
      const t = f.replace(/^\/+/, "").replace(/\/+$/, "");
      if (/:\/\//.test(t)) return t;
      if (/^\?/.test(t)) return t;
      if (/^[^/?]+?[=&]/.test(t)) return "?" + t;
      return "/" + t;
    })
    .join("");

export const delay = (ms: number) =>
  new Promise(res => {
    setTimeout(res, ms);
  });

export const getIDToken = () => Cookies.get("backoffice_id_token");
export const getRefreshToken = () => Cookies.get("backoffice_refresh_token");
export const setIDToken = (idToken: string) => Cookies.set("backoffice_id_token", idToken);
export const setAuth = (idToken: string, refreshToken: string) => {
  Cookies.set("backoffice_id_token", idToken);
  Cookies.set("backoffice_refresh_token", refreshToken);
};
export const removeAuth = () => {
  Cookies.remove("backoffice_id_token");
  Cookies.remove("backoffice_refresh_token");
};
export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  if (!base64Url) throw Error("Invalid token format");
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const validateIDToken = () => {
  const jwt = getIDToken();
  if (!jwt) return false;
  try {
    const decodedJwt = parseJwt(jwt);
    const expirationTime = decodedJwt?.exp;
    const currentTime = Date.now() / 1000;
    return !expirationTime || currentTime < expirationTime;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const refreshTokenIfNeeded = async () => {
  const token = getRefreshToken();
  if (!token) return false;
  if (validateIDToken()) return true;

  try {
    const refreshed = await refreshToken(token);
    setIDToken(refreshed.id_token);
    return true;
  } catch (e) {
    console.log("Refreshing token failed. Please login again. ", e);
    return false;
  }
};

const refreshToken = async (token: string) => {
  const root = process.env.REACT_APP_AUTH_SERVICE_URL;
  const path = "/oauth/token";
  const method = "POST";
  const client_id = process.env.REACT_APP_COGNITO_CLIENT_ID as string;
  const role = "support_admin";
  const grant_type = "refresh_token";
  const redirect_uri = join(window.location.origin, "auth", "/");
  const data = JSON.stringify({ client_id, redirect_uri, role, grant_type, refresh_token: token });

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  let response: Response;

  try {
    response = await fetch(join(root, path), { method, headers, body: data });
  } catch (err) {
    throw Error("Could not reach server");
  }

  if (response.ok) {
    return response.json();
  } else {
    throw Error("Error refreshing token");
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type StyleReducer = {
  [CSSProp in keyof CSSObject]: {
    [prop: string]: CSSObject[CSSProp];
  };
};

// Composes a style from React props.
// 1st argument is stylename/truthy-falsy pairs. The format benefits from the JSX-syntax of passing props.
// 2nd argument is a style reducer which simply is a set of CSS properties mapped to their value by stylename.
// Returns a style.
export const composeStyle = <T extends Record<string, any>>(props: T, styleReducer: StyleReducer) =>
  Object.entries(styleReducer).reduce((acc, [cssProp, options]) => {
    const prop = Object.keys(options).find(prop => !!props[prop]);
    return prop
      ? {
          ...acc,
          [cssProp]: options[prop],
        }
      : acc;
  }, Object.create(null));

// Creates a reducer from styles and CSS overrides. Overrides are particulary useful with fonts and is a reducer itself.
export const createStyleReducer = (styles: Record<string, CSSObject>, overrides: StyleReducer = {}) =>
  Object.entries(styles).reduce(
    (acc1, [styleName, style]) =>
      Object.keys(style).reduce((acc2, _cssProp) => {
        const cssProp = typeof _cssProp === "string" ? toCamelCase(_cssProp) : _cssProp;
        return {
          ...acc2,
          [cssProp]: Object.assign({}, acc2[cssProp], { [styleName]: styles[styleName][_cssProp] }),
        };
      }, acc1),
    overrides
  );

const toCamelCase = (str: string) => str.replace(/-(.)/g, (_, sub) => sub.toUpperCase());

export const stripDeletedTagFromEmail = (email: string) => email?.replace(/-deleted-[^@]+/i, "");

interface HasName {
  first_name?: string | null;
  last_name?: string | null;
}

export const fullName = (profile?: HasName | null) => {
  const { first_name: a, last_name: b } = profile ?? {};
  return [a, b].join(" ").trim();
};

export const typedKeys = Object.keys as <T>(obj: T) => Array<keyof T>;

export const queryKeys = {
  patient: (id: number) => ["patient", id] as const,
  activities: (patientId: number, week?: number) => ["activities", patientId, week] as const,
  patientSearch: (search?: PatientSearchParams) => (search ? (["patient-search", search] as const) : "patient-search"),
  therapist: (id?: number) => (id ? (["therapist", id] as const) : "therapist"),
  therapistSearch: (search?: TherapistSearchParams) =>
    search ? (["therapist-search", search] as const) : "therapist-search",
  insuranceProfile: (patientId: number) => ["insurance-profile", patientId] as const,
  hcps: () => ["hcps"] as const,
  providerGroupProfile: (patientId: number) => ["provider-group-profile", patientId] as const,
  payers: (country: Country) => ["payers", country] as const,
  documents: (patientId: number) => ["documents", patientId] as const,
  careEvents: (patientId: number) => ["care-events", patientId] as const,
  registry: (name: string) => ["registry", name] as const,
  tfSubmissions: (patientId: number) => ["tf-submissions", patientId] as const,
  frInvoices: (month: string) => ["fr-invoices", month] as const,
  claims: (search: ClaimSearch) => ["claims", search] as const,
  reports: (search: ReportSearch) => ["reports", search] as const,
  usInvoices: (invoiceId?: number) => (invoiceId ? (["us-invoices", invoiceId] as const) : "us-invoices"),
  usInvoicePreviews: (payerId: number) => (payerId ? (["us-invoice-preview", payerId] as const) : "us-invoice-preview"),
  unclaimedMedicalReferrals: () => "unclaimed-medical-referrals" as const,
  ukDischargeLetter: (patientId: number) => ["uk-discharge-letter", patientId] as const,
};
