import React from "react";
import { useErrorContext, Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import useTherapist from "../queries/useTherapist";
import { useQueryClient } from "react-query";
import { queryKeys } from "utils/misc";
import { definitions } from "api";
import useChangeRoles from "../queries/useChangeRoles";

type PT = definitions["therapist_full"];
type TRole = "hcp_admin" | "therapist";
type TRoles = {
  label: string;
  value: TRole;
};

const Roles: React.VFC = () => {
  const { t } = useTranslation();
  const setError = useErrorContext();
  const { data: pt } = useTherapist();
  const { mutateAsync } = useChangeRoles();
  const queryClient = useQueryClient();

  if (!pt) return null;

  const roles: TRoles[] = [
    { label: "HCP admin", value: "hcp_admin" },
    { label: "Therapist", value: "therapist" },
  ];
  const options = roles.map(({ value }) => value);

  const change = async (roles: string[]) => {
    const ptKey = queryKeys.therapist(pt.id);
    const rollbackData = queryClient.getQueryData<PT>(ptKey);
    queryClient.cancelQueries(ptKey);
    queryClient.setQueryData<PT>(ptKey, old => ({ ...old!, roles }));

    try {
      await mutateAsync({ roles });
    } catch (err) {
      queryClient.setQueryData(ptKey, rollbackData);
      setError(t`errors.could_not_update_roles`, err);
    } finally {
      queryClient.invalidateQueries(ptKey);
    }
  };

  const getOption = (option: string) => roles.find(role => role.value === option);

  return (
    <div>
      <Text paragraph caption secondary>
        {t("form.roles")}
      </Text>
      <Pane dark>
        <Autocomplete
          disableClearable
          size="small"
          ChipProps={{ color: "secondary" }}
          autoComplete
          autoHighlight
          filterSelectedOptions
          value={pt?.roles ?? []}
          fullWidth
          getOptionLabel={option => `${getOption(option)?.label}`}
          loadingText={<CircularProgress />}
          multiple
          onChange={(_, roles) => change(roles)}
          options={options}
          renderInput={({ InputProps, ...rest }) => (
            <TextField variant="standard" {...rest} InputProps={{ ...InputProps, disableUnderline: true }} />
          )}
        />
      </Pane>
    </div>
  );
};

export default Roles;
