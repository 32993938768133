import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const OP = "PATCH /therapists/{id}";

export type Vars = {
  roles: string[];
};

const useChangeRoles = () => {
  const id = useId();

  return useMutation(({ roles }: Vars) =>
    api.parse(OP, {
      params: [id],
      data: {
        roles,
      },
    })
  );
};

export default useChangeRoles;
