import { Grid } from "@material-ui/core";
import {
  Active,
  Deleted,
  PersonalForm,
  PersonalInfo,
  Summary,
  ToggleFeatures,
  ToggleInflow,
  ToggleJoints,
  ToggleVideoCalls,
} from "./";
import StateLicenses from "./StateLicenses";
import WeeklyPatientLimit from "./WeeklyPatientLimit";
import { ErrorBox, Loading, Pane, Text } from "shared";
import { useTranslation } from "react-i18next";
import useTherapist from "../queries/useTherapist";
import { useState } from "react";
import ToggleSubstitute from "./ToggleSubstitute";
import Roles from "./Roles";

const Account = () => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const { data: pt, error, isLoading, isSuccess } = useTherapist();

  const Personal = edit ? PersonalForm : PersonalInfo;

  const mainEm = pt && (
    <>
      <Pane>{pt.deleted_at ? <Deleted /> : <Active />}</Pane>
      <Pane>
        <Summary />
        <Grid container spacing={1}>
          <Grid item md={6}>
            <ToggleInflow />
          </Grid>
          <Grid item md={6}>
            <ToggleVideoCalls />
          </Grid>
          <Grid item md={6}>
            <ToggleSubstitute />
          </Grid>
        </Grid>
        <Personal setEdit={setEdit} />
        <StateLicenses />
        <ToggleJoints />
        <WeeklyPatientLimit />
        <ToggleFeatures />
        <Roles />
      </Pane>
    </>
  );

  const altEm = (
    <Pane>
      {error && <ErrorBox error={error} verbose dark />}
      {isLoading && <Loading />}
    </Pane>
  );

  return (
    <div>
      <Text paragraph caption secondary>
        {t`patients.account`}
      </Text>
      {isSuccess ? mainEm : altEm}
    </div>
  );
};

export default Account;
