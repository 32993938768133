import { Box, Button } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Pane, Text } from "shared";
import { Breakpoint, SPACING } from "theme";
import { Country } from "utils/market";
import { stripDeletedTagFromEmail } from "utils/misc";
import { css } from "@emotion/css";
import usePatient from "../../queries/usePatient";

interface Props {
  onEdit: () => void;
}

const PersonalInfo: React.VFC<Props> = ({ onEdit }) => {
  const { data: patient } = usePatient();
  const { t } = useTranslation();

  if (!patient) return null;
  const {
    address,
    first_name,
    last_name,
    preferred_name,
    email,
    birthday,
    personal_number,
    phone_number,
    country,
    zip_code,
  } = patient;

  const details = [
    [t`form.first_name`, first_name],
    [t`form.last_name`, last_name],
    [t`form.preferred_name`, preferred_name],
    ["State/ZIP", zip_code],
    [t`form.address`, address],
    [t`form.country`, country],
    [t`form.email`, email && stripDeletedTagFromEmail(email)],
    country === Country.SE ? [t`patients.medical_records.personal_number`, personal_number] : [t`form.birth`, birthday],
    [t`form.phone`, phone_number],
  ];

  return (
    <Pane dark mb={2} data-testid="personal">
      <div className={styles.grid}>
        {details.map(([label, value]) => (
          <React.Fragment key={label}>
            <Text light nowrap>
              {label}
            </Text>
            <Text bold nowrap>
              {value}
            </Text>
          </React.Fragment>
        ))}
      </div>
      <Box pt={2} textAlign="right">
        <Button
          variant="contained"
          color="primary"
          onClick={onEdit}
          endIcon={<EditOutlined fontSize="small" />}
          aria-label="Edit personal information"
        >
          {t`buttons.edit`}
        </Button>
      </Box>
    </Pane>
  );
};

export default PersonalInfo;

const styles = {
  grid: css`
    overflow: auto;
    display: grid;
    grid-auto-rows: 45px;
    align-items: center;
    grid-column-gap: ${SPACING}px;
    @media screen and (max-width: ${Breakpoint.sm - 1}px) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${Breakpoint.sm}px) {
      grid-template-columns: auto 1fr;
    }
  `,
};
